var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"cqjkLNpr2bO1bB1ptPuZC"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const DEPLOYMENT_ENVIRONMENT =
  process.env.DEPLOYMENT_ENVIRONMENT ||
  process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

Sentry.init({
  dsn: DEPLOYMENT_ENVIRONMENT !== 'dev' ? SENTRY_DSN : '',
  environment: DEPLOYMENT_ENVIRONMENT,
  integrations: [
    new BrowserTracing(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: true,
    }),
  ],
  allowUrls: [/visto\.ai/, /visto\.link/],
  tracesSampleRate: 0.2,

  // This is used for all sessions, not just errors
  replaysSessionSampleRate: 0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  ignoreErrors: [
    /Invariant: attempted to hard navigate to the same URL/, // NextJS error when when clicks on a button that the page they are already one, not breaking app
    /ResizeObserver loop completed with undelivered notifications./,
    /null is not an object (evaluating 'a[$d]')/, // Error caused by Google Translate, not breaking app
    "null is not an object (evaluating 'a[$d]')", // Error caused by Google Translate, not breaking app
    /undefined is not an object (evaluating 'a.L')/, // Error caused by Google Translate, not breaking app
    "undefined is not an object (evaluating 'a.L')", // Error caused by Google Translate, not breaking app
  ],
});
